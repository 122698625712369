import React from "react";
import PropTypes from 'prop-types';
import { Link, Heading } from "@lmig/lmds-react";
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import css from 'styled-jsx/css';
import styles from './InsurancePartners.module.scss';

const InsurancePartners = ({
  heading,
  text,
  linkText = '',
  linkHref = '',
  partners = [],
  showTopHeading = false,
}) => {
  const { className: addPartnerClass, styles: addPartnerStyles } = css.resolve`
    img{
      padding: 1.25rem;
    }
  `;
  const { className: linkClass, styles: linkStyles } = css.resolve`
  .lm-Link{
    padding-top: 1rem;
  }
`;

  return (<>
    {showTopHeading && <Heading tag="h2" type="h3" className={styles.insurancePartnersTopHead}>Why choose <strong>Comparion</strong>?</Heading>}
    <div className={styles.insurancePartnersWrapper}>
      <div className={styles.copyWrapper}>
        {heading &&
          <div>{heading}</div>
        }
        <div className={styles.infoText} data-testid="insurancePartners_infoText">
          {text}
          {linkText && <Link className={linkClass} href={linkHref} variant="standalone">{linkText}</Link>}
        </div>
      </div>
      <ul className={styles.partners} data-testid="insurancePartners_partners">{
        partners.map(({ src, alt, width, height, downSize }) => (
          <li key={alt} style={{ height: `58px`, width: '128px' }}>
            <Image imageDomain="IMAGE" preload className={downSize ? addPartnerClass : ""}>
              <img
                className={styles.partnerImage}
                src={`${src}`}
                alt={alt}
                width={width}
                height={height}
              />
            </Image>
          </li>
        ))}
      </ul>
      {addPartnerStyles}
      {linkStyles}
    </div>
  </>);
};

InsurancePartners.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
  partners: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    downSize: PropTypes.bool,
  })),
  showTopHeading: PropTypes.bool
};

export default InsurancePartners;
